import * as React from "react"
import banner_mp4 from "../images/Blokhaus_Banner.mp4"
import banner_webm from "../images/Blokhaus_Banner.webm"
import FullWidthVideo from "../components/Video/FullWidthVideo"
import PrimaryButton from "../components/Buttons/PrimaryButton"
import ArrowButton from "../components/Buttons/ArrowButton"
import Clients from "../components/Clients/Clients"
import ExecutiveCarousel from "../components/Carousel/ExecutiveCarousel"
import Lottie from "../components/Lottie/Lottie"
import { Helmet } from "react-helmet"

import icon1 from "../animations/icon1_75.json";
import icon2 from "../animations/icon2_75.json";
import icon3 from "../animations/icon3_75.json";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barkhaus</title>
      </Helmet>
      <div className={`bg-black text-white`}>
        <img className="w-full h-auto" src='/images/hero.jpg' alt="Barkhaus" />
        <div style={{ maxWidth: 1600 }} className="z-10 relative pt-6 pb-16 px-6 sm:px-12 md:px-24 md:pt-20 md:pb-24 mx-auto">
          <h1 className={`h1-xl mb-6 md:mb-5`}>The Barkchain<br className="block md:hidden" /> &amp; Woof3 Agency.</h1>
          <h2 className={`p mb-8 md:mb-10`}>Barkchain and Woof3 projects move at a pawsome pace. We'll help you chase the car.</h2>
          <PrimaryButton 
            text={`Throw us a bone`}
            url={`/contact`}
            invert={true}
            onDarkBkg={true}
          />
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-12 lg:gap-24 pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-20 mx-auto text-center md:text-left">
          <div className={`w-full md:w-6/12 lg:w-3/12 mb-16`}>
            <h2 className={`h2`}>Barkhaus is a barketing and communications agency, moving at the speed of barkchain and Woof3. And how ever long it takes to get the peanut butter out of the Kong.</h2>
          </div>
          <div className={`w-full md:w-6/12 lg:w-9/12`}>
            <IconAndText 
              icon = {'/images/svgs/barkhaus_1.svg'}
              endFrame = {50}
              headline = {`We’re a barkchain and Woof3 breed.`}
              num={1}
              copy = {`As shoe chewers, cuddlers and leash pullers from across the industry, we see how barkchain is evolving and how it’s impacting the world around us. We know it, because we love and believe in it.`}
            />
            <IconAndText 
              icon = {'/images/svgs/barkhaus_2.svg'}
              endFrame = {25}
              num={2}
              headline = {`We’re focused on the end user (and treats).`}
              copy = {`In the ever-changing world of barkchain, there’s one thing that stays consistent: SQUIRREL! We are not here to ride a hypewave, we are here to truly unleash with our owners in meaningful and useful ways.`}
            />
            <IconAndText 
              icon = {'/images/svgs/barkhaus_3.svg'}
              endFrame = {60}
              num={3}
              headline = {`We’re creative problem solvers.`}
              copy = {`The barkchain category is unique in the myriad of ways you can fetch a stick or catch a ball. We deliver thoughtful and creative-led campaigns that bring ideas to life, as long as it involves belly scratches.`}
            />
          </div>
        </div>
      </div>

      <div className={`text-black`}>
        <Clients />
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-12 lg:gap-32 pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-0 md:pb-20 mx-auto text-left">
          <div className={`w-full md:w-6/12 mb-24 md:mb-16`}>
            <h3 className={`h2 mb-8`}>We are a team of fur babies, frisbee catchers, tail chasers and barkchain believers focused on treats.</h3>
            <PrimaryButton 
              text={`Explore Careers`}
              url={`/careers`}
              invert={true}
            />
          </div>
          <div className={`hidden md:block border-r border-black`}></div>
          <div className={`w-full md:w-6/12 mb-16`}>
            <h3 className={`h2 mb-8`}>Whether you have a Milkbone of an idea or are curious about the dynamic world of barkchain and Woof3, we can help. Let’s dig a hole together.</h3>
            <PrimaryButton 
              text={`Throw us a bone`}
              url={`/contact`}
              invert={true}
            />
          </div>
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-5 pt-0 px-6 sm:px-12 md:px-24 mx-auto mb-12 md:mb-11 text-center md:text-left">
          <ExecutiveCarousel />
        </div>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-5 pt-0 px-6 sm:px-12 md:px-24 mx-auto mb-0 md:mb-20 text-center md:text-left">
          <ArrowButton 
            text={'Meet the pack'}
            url={'/pack/'}
            className={''}
          />
        </div>
      </div>
    </>
  )
}

const IconAndText = ({icon, headline, copy, num, endFrame}) => {
  return <div className={`block lg:flex lg:gap-11 lg:items-start mb-16`}>
            <img src={icon} className={`homepage-lottie lottie-${num} mb-4`} />
            <div>
              <h3 className={`h2 mb-4 md:mb-2`}>{headline}</h3>
              <p className={'p'}>{copy}</p>
            </div>
          </div>
}

export default IndexPage
