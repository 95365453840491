import * as React from "react"
import LinkFadeDown from "../TransitionLinks/LinkFadeDown"

const ArrowButton = ({
  text,
  url,
  invert = false,
  className = ''
}) => {

  return (
    <LinkFadeDown url={url} className={`
      arrow-btn
      table
      text-base md:text-xl
      leading-5 
      px-0 py-2
      font-bold 
      text-center
      box-border
      mx-auto
      ${ invert ? 'text-white' : 'text-black'}
      ${ className }`}>
      {text}<span className='ml-2 inline-block arrow-icon'><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.16323 0.919678L4.25326 1.84596L7.85441 5.51823L0.00028972 5.51823L0.000289663 6.83211L7.85441 6.83211L4.24681 10.4978L5.16323 11.4307L10.3262 6.17517L5.16323 0.919678Z" fill="black"/></svg></span>
    </LinkFadeDown>
  )
}


export default ArrowButton
