import React from "react"
import Slider from "react-slick"
import carouselArrow from "../../images/carousel_arrow.svg"
import Headshot from "../Headshot/Headshot"
import TeamsData from "../../data/teams.json"

const executiveTeamMembers = [
  "Marissa Trew",
  "Nick Garcia",
  "Jennie Putvin",
  "Kyle Kelley",
  
]

function search(nameKey, myArray){
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
          return myArray[i];
      }
  }
}

const homepage_team = []

executiveTeamMembers.forEach((member) => {
  homepage_team.push(search(member, TeamsData))
})

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={carouselArrow} className={'arrow-icon'} alt='Next slide' />
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={carouselArrow} className={'arrow-icon'} alt='Previous slide' />
    </button>
  );
}

const ExecutiveCarousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '40px',
    mobileFirst: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider className={`w-full`} {...settings}>
      {homepage_team.map((member, index) => {
        return (
          <div className={`pl-2 pr-3`} key={index}>
            <Headshot 
              name={member.name}
              title={member.title}
              webm={member.headshot.webm}
              mp4={member.headshot.mp4}
              key={index}
              poster={member.headshot.poster}
              pup_poster={member.headshot.pup}
              pup_title={member.pup_title}
            />
          </div>
        )
      })}
    </Slider>
  );
}

export default ExecutiveCarousel