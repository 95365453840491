import * as React from "react"

const Clients = () => {
  const clients = [
    {
      name : "Blue Buffalo",
      logo : "bluebuffalo.png",
    },
    {
      name : "Chewy",
      logo : "chewy.png",
    },
    {
      name : "Greenies",
      logo : "greenies.png",
    },
    {
      name : "Kong",
      logo : "kong.png",
    },
    {
      name : "Milkbone",
      logo : "milkbone.png",
    },
    {
      name : "Petfinder",
      logo : "petfinder.png",
    },
    {
      name : "PetSmart",
      logo : "petsmart.png",
    },
    {
      name : "Pup-Peroni",
      logo : "puperoni.png",
    },
    {
      name : "Purina",
      logo : "purina.png",
    },
    {
      name : "Rover",
      logo : "rover.png",
    },
  ]

  return (
    <>
      <div className={`bg-black py-11`}>
        <h4 className={`font-vietnam font-bold text-xl text-white text-center`}>And we’ve <span className="italic">barked</span>&nbsp; it for<br className={`block md:hidden`} /> these brands</h4>
      </div>
      <div className={`bg-white pt-4 pb-10 mb-16 lg:pb-28 lg:pt-20 lg:mb-32`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 px-6 sm:px-12 md:px-24 mx-auto">
          {clients.map((client, i)=> {
            return (
              <div className={`${'lg:mx-2 xl:mx-14 xl:mb-4'}`}>
                <img className={`block mx-auto w-full h-auto`} style={{maxWidth : '200px'}} src={`images/client-logos/${client.logo}`} alt={client.name} key={i} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Clients